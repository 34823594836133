import {StoreCheckoutSettings} from '@wix/wixstores-graphql-schema-node';
import {ValueEnabledModel} from '../common/ValueEnabled.model';
import {ValueEnabledTitleModel} from '../common/ValueEnabledTitle.model';

export class PaymentRequestSettingsModel {
  public storeName?: string;
  public logoUrl?: string;
  public termsAndConditions: ValueEnabledModel;
  public privacyPolicy: ValueEnabledModel;
  public returnPolicy: ValueEnabledModel;
  public digitalPolicy: ValueEnabledModel;
  public contactUs: ValueEnabledTitleModel;
  public customPolicy: ValueEnabledTitleModel;

  constructor(paymentRequestSettings?: StoreCheckoutSettings) {
    this.storeName = paymentRequestSettings?.storeName ?? undefined;
    this.logoUrl = paymentRequestSettings?.logo?.url;
    this.termsAndConditions = new ValueEnabledModel(paymentRequestSettings?.termsAndConditions);
    this.privacyPolicy = new ValueEnabledModel(paymentRequestSettings?.privacyPolicy);
    this.returnPolicy = new ValueEnabledModel(paymentRequestSettings?.refundCancellationPolicy);
    this.digitalPolicy = new ValueEnabledModel(paymentRequestSettings?.digitalItemPolicy);
    this.contactUs = new ValueEnabledTitleModel(paymentRequestSettings?.contactUs);
    this.customPolicy = new ValueEnabledTitleModel(paymentRequestSettings?.customPolicy);
  }
}
